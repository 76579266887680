import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #010606;
`;

export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1100px;
  padding-top: 90px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  padding-bottom: 20px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 30px;

  &:hover {
    transition: transform 0.2s;
    transform: scale(1.3);
    color: lightskyblue;
  }
`;
