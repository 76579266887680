import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  Expandable,
  NavMenu,
  NavItem,
  NavLinks,
  ContactBtn,
  ContactBtnLink,
} from "./NavbarElements";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    setScrollNav(window.scrollY >= 150 ? true : false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const toggleFooter = () => {
    scroll.scrollToBottom();
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo onClick={toggleHome}>Jason.Yin</NavLogo>

          <NavMenu>
            <NavItem>
              <NavLinks
                to="about"
                smooth={true}
                duration={400}
                spy={true}
                exact="true"
                offset={-490}
              >
                About
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="experience"
                smooth={true}
                duration={400}
                spy={true}
                exact="true"
                offset={-490}
              >
                Experience
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="portfolio"
                smooth={true}
                duration={400}
                spy={true}
                exact="true"
                offset={-490}
              >
                Skill
              </NavLinks>
            </NavItem>
          </NavMenu>

          <ContactBtn>
            <ContactBtnLink onClick={toggleFooter}>Contact</ContactBtnLink>
          </ContactBtn>

          <Expandable onClick={toggle}>
            <FaBars />
          </Expandable>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
