import styled from "styled-components";
import { Link } from "react-router-dom";

export const SkillsSectionContainer = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #050505;

  @media screen and (max-width: 768px) {
    height: 800px;
  }

  @media screen and (max-width: 480px) {
    height: 800px;
  }
`;

export const SkillsSectionH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 20px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const SkillsContainer = styled.div`
  background-color: #050505;

  @media screen and (max-width: 480px) {
    width: 160px;
  }
`;

export const SkillsWrapper = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const SkillSegmentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SkillSegmentWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SkillSegmentItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: center;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const SkillSegmentTitle = styled.h1`
  font-size: 25px;
  margin-bottom: 16px;
  color: lightskyblue;
`;

export const SkillSegmentName = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
  width: 160px;

  &:hover {
    color: peachpuff;
    transition: 0.3s ease-ease-out;
  }
`;
