import React from "react";
import { FaEnvelope, FaTwitter, FaLinkedin, FaGithub } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialIcons>
          <SocialIconLink
            href="mailto:zichenjyin@gmail.com"
            target="_blank"
            aria-label="Mail"
          >
            <FaEnvelope />
          </SocialIconLink>
          <SocialIconLink
            href="https://twitter.com/zichenjyin"
            target="_blank"
            aria-label="Twitter"
          >
            <FaTwitter />
          </SocialIconLink>
          <SocialIconLink
            href="https://www.linkedin.com/in/zichenyin/"
            target="_blank"
            aria-label="FaLinkedin"
          >
            <FaLinkedin />
          </SocialIconLink>
          <SocialIconLink
            href="https://github.com/awpstew"
            target="_blank"
            aria-label="Github"
          >
            <FaGithub />
          </SocialIconLink>
        </SocialIcons>
        <WebsiteRights>
          © {new Date().getFullYear()} Zichen Yin. All rights reserved.
        </WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
