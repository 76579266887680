export const aboutInfo = {
  id: "about",
  topLine: "About me",
  headline: "Software Engineer",
  description: `
    Artificial Intelligence and Software Engineering graduate from The University of Edinburgh. Fluent in both English and Chinese.
    Passionate about Software Development, with strong communication skills and coding experience for working in a 
    team and successfully completing projects.`,
  imgStartLeft: false,
  img: require("../../images/profile.jpeg").default,
};

export const experienceInfo = {
  id: "experience",
  topLine: "Current Role",
  headline: "Virgin Money",
  description: `
    Currently working as a Software Engineer for Virgin Money developing RESTful APIs 
    using Spring Boot and Backbase Framework as well as learning Front end technologies during spare time.
    Experienced in Scrum and Agile Methodologies.
  `,
  imgStartLeft: true,
  img: require("../../images/company.png").default,
};
