import React from "react";
import {
  SkillsSectionContainer,
  SkillsSectionH1,
  SkillsContainer,
  SkillsWrapper,
  SkillSegmentContainer,
  SkillSegmentWrapper,
  SkillSegmentItems,
  SkillSegmentTitle,
  SkillSegmentName,
} from "./SkillElements";

const SkillsSection = () => {
  return (
    <SkillsSectionContainer id="portfolio">
      <SkillsSectionH1>My Skills</SkillsSectionH1>
      <SkillsContainer>
        <SkillsWrapper>
          <SkillSegmentContainer>
            <SkillSegmentWrapper>
              <SkillSegmentItems>
                <SkillSegmentTitle>Programming Languages</SkillSegmentTitle>
                <SkillSegmentName>Java</SkillSegmentName>
                <SkillSegmentName>Python</SkillSegmentName>
                <SkillSegmentName>JavaScript</SkillSegmentName>
                <SkillSegmentName>SQL</SkillSegmentName>
                <SkillSegmentName>C#</SkillSegmentName>
              </SkillSegmentItems>
              <SkillSegmentItems>
                <SkillSegmentTitle>Technical Skills</SkillSegmentTitle>
                <SkillSegmentName>Performance optimization</SkillSegmentName>
                <SkillSegmentName>RESTful APIs</SkillSegmentName>
                <SkillSegmentName>Git</SkillSegmentName>
                <SkillSegmentName>Agile</SkillSegmentName>
                <SkillSegmentName>Maven</SkillSegmentName>
              </SkillSegmentItems>
            </SkillSegmentWrapper>
            <SkillSegmentWrapper>
              <SkillSegmentItems>
                <SkillSegmentTitle>Industry Softwares</SkillSegmentTitle>
                <SkillSegmentName>JIRA</SkillSegmentName>
                <SkillSegmentName>IntelliJ</SkillSegmentName>
                <SkillSegmentName>Jenkins</SkillSegmentName>
                <SkillSegmentName>Microsoft Office</SkillSegmentName>
                <SkillSegmentName>Android Studio</SkillSegmentName>
              </SkillSegmentItems>
              <SkillSegmentItems>
                <SkillSegmentTitle>Natural Languages</SkillSegmentTitle>
                <SkillSegmentName>Chinese</SkillSegmentName>
                <SkillSegmentName>English</SkillSegmentName>
              </SkillSegmentItems>
            </SkillSegmentWrapper>
          </SkillSegmentContainer>
        </SkillsWrapper>
      </SkillsContainer>
    </SkillsSectionContainer>
  );
};

export default SkillsSection;
