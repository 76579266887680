import ReactRotatingText from "react-rotating-text";
import Video from "../../videos/video.mp4";
import {
  MainContainer,
  MainBackground,
  MainVideoBackground,
  MainContentWrapper,
  MainH1,
  MainP,
} from "./MainElements";

const MainSection = () => {
  const roles = [
    "Software Engineer",
    "Java Developer",
    "Consultant",
    "Backbase Backend Developer",
  ];

  return (
    <MainContainer id="home">
      <MainBackground>
        <MainVideoBackground
          autoPlay
          loop
          muted
          src={Video}
          type="video/mp4"
        ></MainVideoBackground>
      </MainBackground>
      <MainContentWrapper>
        <MainH1>Hi I'm Jason Yin</MainH1>
        <MainP>
          I am a <ReactRotatingText items={roles} pause={2000} />
        </MainP>
      </MainContentWrapper>
    </MainContainer>
  );
};

export default MainSection;
